import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import AddProject from './AddProject';
import AllProjects from './AllProject';

const ProjectTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0); // State for the selected tab

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab index
  };

  const renderComponent = () => {
    // Render the component based on the selected tab
    switch (selectedTab) {
      case 0:
        return <AddProject />;  // Display AddProject component for "Add Project" tab
      case 1:
        return <AllProjects />; // Display AllProjects component for "All Projects" tab
      default:
        return null; // Default case if no valid tab is selected
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Typography variant="h5" gutterBottom>Project Management</Typography>
      <Tabs
        value={selectedTab} // Current selected tab index
        onChange={handleChange} // Handle tab change
        indicatorColor="primary" // Indicator color
        textColor="primary" // Text color of selected tab
        centered // Center the tabs
        sx={{ marginBottom: 2 }} // Style for bottom margin
      >
        <Tab label="Add Project" />     {/* Tab for adding a project */}
        <Tab label="All Projects" />   {/* Tab for viewing all projects */}
      </Tabs>
      <Box sx={{ marginTop: 2 }}>
        {renderComponent()} {/* Render the selected component */}
      </Box>
    </Box>
  );
};

export default ProjectTabs;
