import React, { useState } from 'react';
import { 
  Typography, 
  Container, 
  TextField, 
  Button, 
  Box, 
  CircularProgress, 
  Grid, 
  Chip, 
  Card, 
  CardContent, 
  Snackbar, 
  Alert 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import baseURL from './baseURL';

const AddProject = () => {
  // State variables
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [highlights, setHighlights] = useState('');
  const [images, setImages] = useState(null);
  const [floorPlans, setFloorPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [projectAdded, setProjectAdded] = useState(null);
  const navigate = useNavigate();

  // Handle image selection
  const handleImageChange = (e) => {
    const files = e.target.files;
    // Validate image files
    const validImageFiles = Array.from(files).filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024 // 5MB limit
    );
    
    if (validImageFiles.length !== files.length) {
      setError('Some files are not valid images or exceed 5MB limit');
    }
    
    setImages(validImageFiles.length > 0 ? validImageFiles : null);
  };

  // Handle floor plan selection
  const handleFloorPlanChange = (e) => {
    const files = e.target.files;
    // Validate floor plan files
    const validFloorPlanFiles = Array.from(files).filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024 // 5MB limit
    );
    
    if (validFloorPlanFiles.length !== files.length) {
      setError('Some floor plan files are not valid images or exceed 5MB limit');
    }
    
    setFloorPlans(validFloorPlanFiles.length > 0 ? validFloorPlanFiles : null);
  };

  // Handle highlights input
  const handleHighlightsChange = (e) => {
    setHighlights(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    if (!title || !description || !contactNo) {
      setError('Please fill in all required fields');
      return;
    }

    // Convert highlights into an array
    const highlightsArray = highlights 
      ? highlights.split(',').map((highlight) => highlight.trim()).filter(Boolean)
      : [];

    // Prepare form data
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('contactNo', contactNo);
    formData.append('highlights', JSON.stringify(highlightsArray));

    // Append selected images and floor plans to the form data
    if (images && images.length > 0) {
      images.forEach((image) => formData.append('images', image));
    }
    if (floorPlans && floorPlans.length > 0) {
      floorPlans.forEach((floorPlan) => formData.append('floorPlans', floorPlan));
    }

    // Get the token from localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login'); // Redirect to login if no token is found
      return;
    }

    // Set loading state
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await fetch(`${baseURL}/api/projects`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      // Handle response
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create project');
      }

      const result = await response.json();
      
      // Success handling
      setProjectAdded(result);
      setSuccessMessage('Project added successfully!');

      // Reset form fields
      setTitle('');
      setDescription('');
      setContactNo('');
      setHighlights('');
      setImages(null);
      setFloorPlans(null);

    } catch (error) {
      console.error('Project Creation Error:', error);
      setError(error.message || 'Failed to create project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Close error message
  const handleCloseError = () => {
    setError(null);
  };

  // Close success message
  const handleCloseSuccess = () => {
    setSuccessMessage(null);
  };

  return (
    <Container maxWidth="md">
      {/* Error Snackbar */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseError} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      {/* Success Snackbar */}
      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={6000} 
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSuccess} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 3 }}>
        Add New Project
      </Typography>
      
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              helperText="Enter the project title"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              helperText="Provide a detailed description of the project"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Contact No"
              variant="outlined"
              fullWidth
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
              required
              helperText="Enter contact number for the project"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Highlights (comma separated)"
              variant="outlined"
              fullWidth
              value={highlights}
              onChange={handleHighlightsChange}
              helperText="Enter project highlights separated by commas"
            />

            {highlights && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Highlights:</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                  {highlights.split(',').map((highlight, index) => (
                    <Chip
                      key={index}
                      label={highlight.trim()}
                      sx={{ m: 0.5 }}
                      color="primary"
                      size="small"
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Grid>

          {/* Image Upload Section */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Project Images
            </Typography>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{
                py: 2,
                borderStyle: 'dashed',
                borderColor: 'primary.main',
              }}
            >
              Select Project Images
              <input
                type="file"
                accept="image/*"
                multiple
                hidden
                onChange={handleImageChange}
              />
            </Button>
            
            {images && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {images.map((file, index) => (
                  <Grid item xs={4} key={index}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ 
                        width: '100%', 
                        height: 200, 
                        objectFit: 'cover', 
                        borderRadius: 8 
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {/* Floor Plans Upload Section */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Floor Plans
            </Typography>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{
                py: 2,
                borderStyle: 'dashed',
                borderColor: 'secondary.main',
              }}
            >
              Select Floor Plans
              <input
                type="file"
                accept="image/*"
                multiple
                hidden
                onChange={handleFloorPlanChange}
              />
            </Button>
            
            {floorPlans && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {floorPlans.map((file, index) => (
                  <Grid item xs={4} key={index}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ 
                        width: '100%', 
                        height: 200, 
                        objectFit: 'cover', 
                        borderRadius: 8 
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth 
              disabled={loading}
              sx={{ py: 1.5, mt: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Submit Project'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Project Added Details */}
      {projectAdded && (
        <Card sx={{ mt: 4, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" color="primary" gutterBottom>
              Project Details
            </Typography>
            
            <Typography variant="h6">{projectAdded.title}</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {projectAdded.description}
            </Typography>
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">
                Contact: {projectAdded.contactNo}
              </Typography>
              
              {projectAdded.highlights && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Highlights:</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {JSON.parse(projectAdded.highlights).map((highlight, index) => (
                      <Chip
                        key={index}
                        label={highlight}
                        color="secondary"
                        size="small"
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default AddProject;
