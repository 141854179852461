import React, { useEffect, useState } from 'react';
import { Typography, Container, CircularProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Modal, Backdrop, Fade, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Added Trash icon
import baseURL from './baseURL';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null); // Store the selected project for detailed view
  const [openModal, setOpenModal] = useState(false); // Manage modal visibility
  const [isEditing, setIsEditing] = useState(false); // Track if in edit mode
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchProjects = async () => {
      try {
        const response = await fetch(`${baseURL}/api/projects`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }

        const data = await response.json();
        setProjects(data);
      } catch (error) {
        setError('An error occurred while fetching projects.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [navigate]);

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setOpenModal(true);
    setIsEditing(false); // Reset edit mode
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProject(null); // Reset selected project
    setIsEditing(false); // Reset edit mode
  };

  const handleEditClick = () => {
    setIsEditing(true); // Switch to edit mode
  };

  const handleDelete = (projectId) => {
    const token = localStorage.getItem('authToken');
    fetch(`${baseURL}/api/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        setProjects((prevProjects) => prevProjects.filter((project) => project._id !== projectId));
        handleCloseModal();
      })
      .catch(() => {
        setError('Failed to delete project');
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedProject = {
      ...selectedProject,
      // Include logic here to update the project data
      // For example, update images, floor plans, title, description, etc.
    };

    // You can handle image uploads or use existing image URLs
    const token = localStorage.getItem('authToken');
    fetch(`${baseURL}/api/projects/${selectedProject._id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProject),
    })
      .then((response) => response.json())
      .then((data) => {
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === updatedProject._id ? updatedProject : project
          )
        );
        handleCloseModal();
      })
      .catch((error) => {
        setError('Failed to update project');
      });
  };

  const handleImageChange = (e, type) => {
    // Handle image upload logic here for project images or floor plans
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newProject = { ...selectedProject };
        if (type === 'image') {
          newProject.images = [...newProject.images, reader.result];
        } else if (type === 'floorPlan') {
          newProject.floorPlans = [...newProject.floorPlans, reader.result];
        }
        setSelectedProject(newProject);
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Projects</Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Project Name</strong></TableCell>
              <TableCell><strong>Created At</strong></TableCell>
              <TableCell><strong>View</strong></TableCell>
              <TableCell><strong>Edit</strong></TableCell>
              <TableCell><strong>Delete</strong></TableCell> {/* New Delete column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow key={project._id}>
                <TableCell>{project.title}</TableCell>
                <TableCell>{new Date(project.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenModal(project)} aria-label="view project">
                    <FaEye style={{ fontSize: '1.5rem', color: '#1976d2' }} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenModal(project)} aria-label="edit project">
                    <FaEdit style={{ fontSize: '1.5rem', color: '#1976d2' }} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(project._id)} aria-label="delete project">
                    <FaTrash style={{ fontSize: '1.5rem', color: '#d32f2f' }} />
                  </IconButton>
                </TableCell> {/* Delete icon */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for displaying detailed project data */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}>
            <Typography variant="h5" gutterBottom>{selectedProject?.title}</Typography>
            {isEditing ? (
              <form onSubmit={handleFormSubmit}>
                <TextField
                  fullWidth
                  label="Project Title"
                  value={selectedProject?.title || ''}
                  onChange={(e) => setSelectedProject({ ...selectedProject, title: e.target.value })}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={selectedProject?.description || ''}
                  onChange={(e) => setSelectedProject({ ...selectedProject, description: e.target.value })}
                  sx={{ marginBottom: 2 }}
                />
                {/* New Fields for contactNo and highlights */}
                <TextField
                  fullWidth
                  label="Contact No"
                  value={selectedProject?.contactNo || ''}
                  onChange={(e) => setSelectedProject({ ...selectedProject, contactNo: e.target.value })}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  fullWidth
                  label="Highlights"
                  value={selectedProject?.highlights || ''}
                  onChange={(e) => setSelectedProject({ ...selectedProject, highlights: e.target.value })}
                  sx={{ marginBottom: 2 }}
                />
                {/* For Images */}
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e, 'image')}
                  multiple
                  style={{ marginBottom: 2 }}
                />
                {/* For Floor Plans */}
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e, 'floorPlan')}
                  multiple
                  style={{ marginBottom: 2 }}
                />
                <Button type="submit" variant="contained" color="primary">Save Changes</Button>
              </form>
            ) : (
              <div>
                <Typography variant="body1"><strong>Description: </strong>{selectedProject?.description}</Typography>
                {/* Add other fields here */}
              </div>
            )}
            <Button onClick={handleCloseModal} variant="contained" color="secondary">Close</Button>
            {isEditing && <Button onClick={handleEditClick} variant="contained" color="primary">Edit</Button>}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default Projects;
