import React from 'react';
import { Tabs, Tab, AppBar, Toolbar, Button, Menu, MenuItem, Typography, Box } from '@mui/material';
import { useNavigate, Routes, Route } from 'react-router-dom';
import ProjectTabs from './ProjectTabs';
import Inquiry from './Inquiry';

const Dashboard = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>Jain Life Spaces</Typography>
        
        </Toolbar>
      </AppBar>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Projects" />
        <Tab label="Inquiry" />
      </Tabs>
      <Box p={3}>
        {tabIndex === 0 && <ProjectTabs />}
        {tabIndex === 1 && <Inquiry />}
      </Box>
    </Box>
  );
};

export default Dashboard;
