import React, { useEffect, useState } from 'react';
import { Typography, Container, CircularProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import baseURL from './baseURL';

const Inquiry = () => {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated by checking the token in localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      // If no token, redirect to the login page
      navigate('/login');
      return;
    }

    // Fetch inquiry data from the API
    const fetchInquiries = async () => {
      try {
        const response = await fetch(`${baseURL}/api/inquiries/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Send the token in the Authorization header
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch inquiries');
        }

        const data = await response.json();
        setInquiries(data); // Assuming data is an array of inquiries
      } catch (error) {
        setError('An error occurred while fetching inquiries.');
      } finally {
        setLoading(false);
      }
    };

    fetchInquiries();
  }, [navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // Helper function to format date as dd/mm/yyyy
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Inquiries</Typography>

      {/* Display the table of inquiries */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Mobile No</strong></TableCell>
              <TableCell><strong>Date</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inquiries.map((inquiry) => (
              <TableRow key={inquiry._id}>
                <TableCell>{inquiry.name}</TableCell>
                <TableCell>{inquiry.mobileNo}</TableCell>
                <TableCell>{formatDate(inquiry.createdAt)}</TableCell> {/* Apply the custom date format */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Inquiry;
